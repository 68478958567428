<template>
  <div>
    <vs-table
      class="table-list"
      noDataText="Nenhum endosso encontrado"
      :data="endossoList || []"
      :hoverFlat="true"
    >
      <template slot="thead">
        <vs-th class="text-center">Nº endosso</vs-th>
        <vs-th class="text-center">Proposta do endosso</vs-th>
        <vs-th class="text-center">Dt. emissão</vs-th>
        <vs-th class="text-center">Início da vigência</vs-th>
        <vs-th class="text-center">Fim da vigência</vs-th>
        <vs-th class="text-center">Tipo do endosso</vs-th>
        <vs-th class="text-center">IS</vs-th>
        <vs-th class="text-center">Prêmio</vs-th>
        <vs-th class="text-center">Situação</vs-th>
        <vs-th class="text-center"></vs-th>
      </template>

      <template slot-scope="{ data }">
        <vs-tr :data="item" :key="item.Number" v-for="item in data">
          <vs-td :data="item.Number" class="text-center font-semibold">
            {{ item.Number }}
          </vs-td>

          <vs-td :data="item.Proposal.Number" class="text-center">
            {{ item.ProposalType.Id == 3 ? "NA" : item.Proposal.Number }}
          </vs-td>

          <vs-td :data="item.IssueDate" class="text-center">
            {{
              $utils.dateToDDMMYYYY(item.IssueDate) == "01/01/0001"
                ? "NA"
                : $utils.dateToDDMMYYYY(item.IssueDate)
            }}
          </vs-td>

          <vs-td :data="item.Proposal.StartDate" class="text-center">
            {{
              item.Proposal.StartDate
                ? $utils.dateToDDMMYYYY(item.Proposal.StartDate)
                : "-"
            }}
          </vs-td>

          <vs-td :data="item.Proposal.EndDate" class="text-center">
            {{
              item.Proposal.EndDate
                ? $utils.dateToDDMMYYYY(item.Proposal.EndDate)
                : "-"
            }}
          </vs-td>

          <vs-td :data="item.EndorsementType.Name" class="text-center">
            {{ item.EndorsementType.Name }}
          </vs-td>

          <vs-td
            :data="item.Proposal.InsuredAmountValue"
            class="text-center w-1/12"
          >
            {{
              item.ProposalType.Id == 3
                ? "NA"
                : $utils.formatStrMoney(
                    item.Proposal.InsuredAmountValue,
                    2,
                    ",",
                    "."
                  )
            }}
          </vs-td>

          <vs-td
            :data="item.Proposal.InsurancePremium"
            class="text-center w-1/12"
          >
            {{
              item.ProposalType.Id == 3
                ? "NA"
                : $utils.formatStrMoney(
                    item.Proposal.InsurancePremium,
                    2,
                    ",",
                    "."
                  )
            }}
          </vs-td>

          <vs-td :data="item.Status.Name" class="text-center">
            {{ item.ProposalType.Id == 3 ? "NA" : item.Status.Name }}
          </vs-td>

          <vs-td class="text-center">
            <vs-dropdown
              vs-custom-content
              vs-trigger-click
              class="btn-action-apolice"
              :disabled="item.ProposalType.Id == 3 ? true : false"
            >
              <vs-button color="dark" type="flat" title="Ações">
                <i class="onpoint-dots-three-vertical icon-dots-three-vertical" role="button"></i>
              </vs-button>
              <vs-dropdown-menu class="btn-action-apolice-options">
                <vs-dropdown-item
                  @click="
                    loadDownloadEndorsementDocument(item.Proposal.UniqueId)
                  "
                >
                  <p class="text-base px-6 py-2">Download endosso</p>
                </vs-dropdown-item>

                <vs-dropdown-item
                  @click="showModalParcelas(item.Proposal.UniqueId)"
                  v-if="item.Proposal.InsurancePremium > 0"
                  divider
                >
                  <p class="text-base px-6 py-2">Download do(s) boleto(s)</p>
                </vs-dropdown-item>

                <vs-dropdown-item
                  @click="downloadLaudoEmissaoClick(item.Proposal.UniqueId)"
                  divider
                >
                  <p class="text-base px-6 py-2">
                    Download do laudo de emissão
                  </p>
                </vs-dropdown-item>

                <vs-dropdown-item
                  divider
                  v-if="
                    item.PolicyNumber != null &&
                    item.AttachmentCount &&
                    item.AttachmentCount > 0
                  "
                  @click="showEndorsementAttachmentsModal(item.EndorsementId)"
                >
                  <p class="text-base px-6 py-2">Download do(s) anexo(s)</p>
                </vs-dropdown-item>
              </vs-dropdown-menu>
            </vs-dropdown>
          </vs-td>
        </vs-tr>
      </template>
    </vs-table>

    <vs-popup
      id="listar-documentos-endosso-modal"
      title=" "
      :active.sync="endossoAnexosModal"
    >
      <h2 class="text-primary">Documentos do endosso</h2>

      <vs-table
        class="table-list mt-5"
        maxHeight="600px"
        :data="endossoAnexos"
        :maxItems="endossoAnexos.length"
        :hoverFlat="true"
      >
        <template slot="thead">
          <vs-th class="text-center"> Título </vs-th>
          <vs-th class="text-center"> Status </vs-th>
          <vs-th class="text-center"> Data Upload </vs-th>
          <vs-th class="text-center"> Obrigatório </vs-th>

          <vs-th></vs-th>
        </template>

        <template slot-scope="{ data }">
          <vs-tr :data="item" :key="indextr" v-for="(item, indextr) in data">
            <vs-td class="text-center">
              {{ item.Name }}
            </vs-td>
            <vs-td class="text-center">
              {{ item.StatusName }}
            </vs-td>
            <vs-td class="text-center">
              {{ $utils.dateToDDMMYYYY(item.UpdateDate) }}
            </vs-td>
            <vs-td class="text-center">
              {{ item.IsRequired ? "Sim" : "Não" }}
            </vs-td>
            <vs-td class="text-center">
              <span class="flex">
                <vs-button
                  radius
                  class="visualizar-anexos"
                  color="primary"
                  type="flat"
                  icon="get_app"
                  v-if="item.TotalArchives > 0"
                  @click="downloadEndorsementAttachment(item.UniqueId)"
                ></vs-button>
              </span>
            </vs-td>
          </vs-tr>
        </template>
      </vs-table>
    </vs-popup>

    <vs-popup
      id="listar-anexos-documentos-endosso-modal"
      title=" "
      :active.sync="endossoAnexosArquivosModal"
    >
      <h2 class="text-primary">Arquivos do anexo</h2>

      <vs-table
        class="table-list mt-5"
        maxHeight="600px"
        :data="endossoAnexosArquivos"
        :maxItems="endossoAnexosArquivos.length"
        :hoverFlat="true"
      >
        <template slot="thead">
          <vs-th class="text-left"> Nome </vs-th>
          <vs-th></vs-th>
        </template>

        <template slot-scope="{ data }">
          <vs-tr :data="item" :key="indextr" v-for="(item, indextr) in data">
            <vs-td class="text-left">
              {{ item.OriginalFilename + item.Extension }}
            </vs-td>
            <vs-td class="w-1/12">
              <span class="flex">
                <vs-button
                  radius
                  class="visualizar-anexos"
                  color="primary"
                  type="flat"
                  icon="get_app"
                  @click="downloadAttachmentFile(item)"
                ></vs-button>
              </span>
            </vs-td>
          </vs-tr>
        </template>
      </vs-table>
    </vs-popup>

    <listar-parcelas-popup
      v-if="!!boleto.uniqueId"
      :parcelasModal="boleto.showModal"
      :propostaUniqueId="boleto.uniqueId"
      @fecharParcelasModal="boleto = { showModal: false }"
      :autoLoad="true"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import ListarParcelasPopup from "@/views/propostas/components/visualizacao/ListarParcelasPopup.vue";

export default {
  components: { ListarParcelasPopup },
  data() {
    return {
      endossoAnexosArquivosModal: false,
      endossoAnexosModal: false,
      endossoAnexos: [],
      endossoAnexosArquivos: [],
      boleto: { showModal: false, uniqueId: null },
    };
  },
  computed: {
    ...mapGetters("endosso-module", ["endossoList"]),

    endossoListModal: {
      get() {
        return this.endossoModal;
      },

      set() {
        this.$emit("fecharListarEndossoModal");
      },
    },
  },
  methods: {
    ...mapActions("endosso-module", [
      "getEndorsementAttachmentsList",
      "getEndorsementRequestDocumentArchives",
      "downloadEndorsementArchive",
      "downloadEndorsementDocument",
    ]),

    ...mapActions("propostas-module", ["downloadLaudoEmissao"]),
    async downloadLaudoEmissaoClick(propostaUniqueId) {
      await this.$onpoint.loading(async () => {
        return await this.downloadLaudoEmissao(propostaUniqueId)
          .then((urlToDownloadIssuanceReport) => {
            window.open(urlToDownloadIssuanceReport, "_self");
          })
          .catch((err) => this.$onpoint.errorModal(err.response.data.Errors));
      });
    },

    showEndorsementAttachmentsModal(endorsementId) {
      this.$onpoint.loading(() => {
        return this.getEndorsementAttachmentsList(endorsementId).then(
          (response) => {
            this.endossoAnexos = response;
            this.endossoAnexosModal = true;
          }
        );
      });
    },

    showModalParcelas(uniqueId) {
      this.boleto = { showModal: true, uniqueId: uniqueId };
    },

    downloadEndorsementAttachment(requestDocumentUniqueId) {
      this.$onpoint.loading(() => {
        return this.getEndorsementRequestDocumentArchives(
          requestDocumentUniqueId
        )
          .then((response) => {
            this.endossoAnexosArquivos = response;

            if (response.length == 1) {
              this.downloadAttachmentFile(response[0]);
            } else {
              this.endossoAnexosArquivosModal = true;
            }
          })
          .catch((ex) => {
            this.$onpoint.errorModal(ex);
          });
      });
    },

    downloadAttachmentFile(file) {
      this.$onpoint.loading(() => {
        return this.downloadEndorsementArchive(file.UniqueId)
          .then((data) => {
            window.open(data.UrlToDownload, "_blank");
          })
          .catch((ex) => {
            this.$onpoint.errorModal(ex);
          });
      });
    },

    loadDownloadEndorsementDocument(endorsementUniqueId) {
      this.$onpoint.loading(() => {
        return this.downloadEndorsementDocument(endorsementUniqueId)
          .then((data) => {
            window.open(data.UrlToDownload, "_blank");
          })
          .catch((ex) => {
            this.$onpoint.errorModal(ex);
          });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .vs-table--thead {
  tr {
    th.text-center .vs-table-text {
      justify-content: center;
    }
  }
}

.icon-dots-three-vertical::before{
  position: relative;
  top: 2px;
  font-size: 24px;
  color: #afafaf;
}
</style>
