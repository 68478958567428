<template>
  <div>
    <vs-popup
      id="listar-arquivos-modal"
      title=""
      :active.sync="showArquivosModal"
    >
      <h2 class="text-primary">Arquivos</h2>
      <vs-table
        class="table-list mt-5"
        maxHeight="600px"
        :data="arquivos"
        :maxItems="arquivos.length"
        :hoverFlat="true"
      >
        <template slot="thead">
          <vs-th class="text-center">
            Descrição
          </vs-th>
          <vs-th class="text-center">
            Tipo
          </vs-th>
          <vs-th class="text-center">
            Data
          </vs-th>
          <vs-th></vs-th>
        </template>
        <template slot-scope="{ data }">
          <vs-tr :data="item" :key="indextr" v-for="(item, indextr) in data">
            <vs-td class="text-center">
              {{ item.Description }}
            </vs-td>
            <vs-td class="text-center">
              {{ formatTypeName(item.PolicyHistoryArchiveTypeId) }}
            </vs-td>
            <vs-td class="text-center">
              {{ $utils.dateToDDMMYYYYHHmm(item.CreateDate) }}
            </vs-td>
            <vs-td class="text-center">
              <span class="flex">
                <vs-button
                  radius
                  color="primary"
                  type="flat"
                  icon="get_app"
                  @click="downloadArchive(item.ArchiveUniqueId)"
                ></vs-button>
              </span>
            </vs-td>
          </vs-tr>
        </template>
      </vs-table>
    </vs-popup>
  </div>
</template>
<script>
const EPolicyHistoryArchiveType = Object.freeze([
  { Id: 0, Name: "Desconhecido" },
  { Id: 1, Name: "Apólice" },
  { Id: 2, Name: "Boleto" },
  { Id: 3, Name: "Endosso" },
  { Id: 4, Name: "Outros" }
]);

import axiosInstance from "@/axios";

export default {
  name: "listar-arquivos-popup",
  props: {
    arquivosModal: {
      type: Boolean,
      required: true
    },
    arquivos: {
      type: Array,
      required: true
    }
  },
  data() {
    return {};
  },
  computed: {
    showArquivosModal: {
      get() {
        return this.arquivosModal;
      },
      set() {
        this.$emit("fecharArquivosModal");
      }
    }
  },
  mounted() {},
  methods: {
    downloadArchive(archiveUniqueId) {
      this.$onpoint.loading(() => {
        return axiosInstance
          .get(
            `/api/Application/GetUrlFileDownload?ArchiveUniqueId=${archiveUniqueId}`
          )
          .then(response => (response.data || {}).Response)
          .then(response => {
            window.open(response, "_self");
          })
          .catch(ex => {
            this.$onpoint.errorModal(ex.response.data.Errors);
          });
      });
    },
    formatTypeName(value) {
      return EPolicyHistoryArchiveType.find(x => x.Id == value).Name;
    }
  }
};
</script>
<style lang="scss">
@import "../../../assets/scss/mixins";
$margin-color: #f6f6f6;
#listar-arquivos-modal {
  z-index: 53001;
}
#listar-arquivos-modal {
  .vs-popup {
    width: 800px !important;
    max-height: 800px !important;
  }
}
#listar-arquivos-modal {
  .vs-popup {
    .vs-popup--content {
      padding: 0 20px 20px 20px;
      i {
        font-size: 25px;
      }
    }
  }
  .vs-table--search {
    max-width: initial;
    margin-bottom: 30px;
    i {
      font-size: 2em;
      @include sm {
        margin-top: -22px;
      }
    }
    input {
      padding-left: 40px !important;
      font-size: 1.4em !important;
      width: 370px;
      height: 47px;
      @include sm {
        margin-top: -22px;
      }
    }
  }
  th .sort-th,
  th .vs-table-text {
    display: inline-flex !important;
    color: #9c9c9c !important;
  }
  th {
    color: #9c9c9c !important;
    text-transform: uppercase;
    font-weight: bold !important;
  }
  th .sort-th .th-sort-icon {
    font-size: 1.5rem;
  }
  .vs-con-table .vs-con-tbody {
    background: transparent !important;
    border: none !important;
    overflow-x: auto;
  }
  thead tr {
    height: 0;
    th {
      padding-bottom: 0;
    }
  }
  .tr-values {
    height: 80px;
  }
  table {
    border-collapse: separate;
    border-spacing: 0 30px;
    // position: relative;
    // display: block;
    // overflow: auto;
  }
  td {
    border-top: 1px solid $margin-color;
    border-bottom: 1px solid $margin-color;
    padding: 15px;
    min-height: 25px;
    cursor: default;
    button {
      cursor: pointer;
      padding: 8px !important;
      font-size: 12px;
    }
  }
  td:first-child {
    border-left: 1px solid $margin-color !important;
    padding-left: 30px;
  }
  td:last-child {
    border-right: 1px solid $margin-color !important;
    padding-right: 30px;
  }
}
</style>
