<template>
  <section class="detalhe-apolice ">
    <div class="m-1 mr-4 px-0">
      <breadcrumb
        title="Apólice"
        actualPage="Detalhes da apólice"
        previousPage="apolices"
        previousPageTitle="Lista de apólices"
        class="mb-6"
      />
    </div>
    <div class="detalhe-apolice new-tabs">
      <vs-tabs color="warning" v-if="policy.PolicyUniqueId">
        <vs-tab label="Dados gerais">
          <detalhar-apolice :policy="policy" />
        </vs-tab>

        <vs-tab label="Histórico">
          <historico-apolice :history="policy.PolicyHistory" />
        </vs-tab>

        <vs-tab label="Endosso(s)" v-if="policy.HasEndorsement">
          <endosso-list :policyUniqueId="policy.PolicyUniqueId" />
        </vs-tab>
      </vs-tabs>
    </div>
  </section>
</template>

<script>
import { mapActions } from "vuex";
import Breadcrumb from "@/layouts/components/breadcrumb/Breadcrumb";
import DetalharApolice from "./components/DetalharApolice.vue";
import HistoricoApolice from "./components/HistoricoApolice.vue";
import EndossoList from "./components/EndossoList.vue";

export default {
  name: "detalhar-apolices",
  components: {
    Breadcrumb,
    DetalharApolice,
    HistoricoApolice,
    EndossoList
  },
  data() {
    return {
      policyUniqueId: this.$route.params.policyUniqueId,
      policy: Object
    };
  },
  created() {
    this.$onpoint.loading(() => {
      return this.loadApolicesHistory(this.policyUniqueId).then(
        resp => (this.policy = resp)
      );
    });
    this.$appInsights.trackPageView({
      name: "detalhar-apolice",
      url: window.location.href
    });
  },
  methods: {
    ...mapActions("apolice-module", ["loadApolicesHistory"])
  }
};
</script>

<style lang="scss">
.perfil-corretora-tabs {
  background: white;
  border-radius: 8px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}
.detalhe-apolice {
  .con-vs-tabs {
    display: flex;
  }
  .con-ul-tabs {
    background: white;
    box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    width: 250px;
    padding: 50px 20px;
    margin-bottom: 10px;
  }
  .line-vs-tabs {
    margin-top: 50px;
    margin-left: 20px;
  }
  .vs-tabs--li button.vs-tabs--btn {
    margin-top: 30px;
    width: auto;
  }
  .vs-tabs--ul {
    box-shadow: none;
    flex-direction: column;
  }
  .vs-tabs--li {
    padding-right: 15px;
  }
  .con-slot-tabs {
    background: white;
    border-radius: 10px;
    margin: 0 20px 10px 5px;
  }
}
</style>
