var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "vs-collapse",
        _vm._l(_vm.histories, function(item, key) {
          return _c("vs-collapse-item", { key: key }, [
            _c(
              "div",
              {
                staticClass: "vx-row",
                attrs: { slot: "header" },
                slot: "header"
              },
              [
                _c("div", { staticClass: "w-full" }, [
                  _c(
                    "p",
                    {
                      staticClass: "font-bold",
                      staticStyle: { "font-size": "0.83em !important" }
                    },
                    [
                      _vm._v(
                        "\n            " +
                          _vm._s(
                            _vm.$utils.dateToDDMMYYYYHHmm(item.CreateDate)
                          ) +
                          " -\n            " +
                          _vm._s(item.Observation) +
                          "\n          "
                      )
                    ]
                  ),
                  _c(
                    "p",
                    { staticStyle: { "font-size": "0.75em !important" } },
                    [
                      _vm._v(
                        "\n            Criado por: " +
                          _vm._s(item.CreateByPerson.Name) +
                          "\n          "
                      )
                    ]
                  )
                ])
              ]
            ),
            item.changeParse.length > 0
              ? _c(
                  "div",
                  [
                    (item.Archives || []).length > 0
                      ? _c(
                          "div",
                          { staticClass: "text-right mb-5" },
                          [
                            _c(
                              "vs-button",
                              {
                                attrs: { color: "primary" },
                                on: {
                                  click: function($event) {
                                    _vm.selectedArquivos = item.Archives
                                    _vm.arquivosModal = true
                                  }
                                }
                              },
                              [
                                _c(
                                  "span",
                                  { staticClass: "font-semibold pr-2 pl-2" },
                                  [
                                    _vm._v(
                                      "\n              Visualizar arquivo(s)\n            "
                                    )
                                  ]
                                )
                              ]
                            )
                          ],
                          1
                        )
                      : _vm._e(),
                    _c(
                      "vs-table",
                      {
                        staticClass: "change_values",
                        attrs: {
                          hoverFlat: true,
                          noDataText: "Nenhuma alteração disponível",
                          data: item.changeParse
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "default",
                              fn: function(ref) {
                                var data = ref.data
                                return item
                                  ? _vm._l(data, function(tr, indextr) {
                                      return _c(
                                        "vs-tr",
                                        { key: indextr, attrs: { data: tr } },
                                        [
                                          _c("vs-td", [
                                            _vm._v(_vm._s(tr.Property))
                                          ]),
                                          _c("vs-td", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.formatPropertyValue(
                                                  tr.Type,
                                                  tr.OldValue
                                                )
                                              )
                                            )
                                          ]),
                                          _c("vs-td", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.formatPropertyValue(
                                                  tr.Type,
                                                  tr.NewValue
                                                )
                                              )
                                            )
                                          ])
                                        ],
                                        1
                                      )
                                    })
                                  : undefined
                              }
                            }
                          ],
                          null,
                          true
                        )
                      },
                      [
                        _c(
                          "template",
                          { slot: "thead" },
                          [
                            _c("vs-th", [_vm._v("Campo alterado")]),
                            _c("vs-th", [_vm._v("Valor anterior")]),
                            _c("vs-th", [_vm._v("Novo valor")])
                          ],
                          1
                        )
                      ],
                      2
                    )
                  ],
                  1
                )
              : _c("div", { staticClass: "vx-row" }, [
                  _c("div", { staticClass: "w-1/2" }, [
                    _c("p", [_vm._v("Nenhuma alteração disponível")])
                  ]),
                  (item.Archives || []).length > 0
                    ? _c(
                        "div",
                        { staticClass: "w-1/2 text-right" },
                        [
                          _c(
                            "vs-button",
                            {
                              staticClass: "mr-5",
                              attrs: { color: "primary" },
                              on: {
                                click: function($event) {
                                  _vm.selectedArquivos = item.Archives
                                  _vm.arquivosModal = true
                                }
                              }
                            },
                            [
                              _c(
                                "span",
                                { staticClass: "font-semibold pr-2 pl-2" },
                                [
                                  _vm._v(
                                    "\n              Visualizar arquivo(s)\n            "
                                  )
                                ]
                              )
                            ]
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ])
          ])
        }),
        1
      ),
      _c("listar-arquivos-popup", {
        attrs: {
          arquivosModal: _vm.arquivosModal,
          arquivos: _vm.selectedArquivos
        },
        on: {
          fecharArquivosModal: function($event) {
            _vm.arquivosModal = false
            _vm.selectedArquivos = []
          }
        }
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }