var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("section", { staticClass: "detalhe-apolice " }, [
    _c(
      "div",
      { staticClass: "m-1 mr-4 px-0" },
      [
        _c("breadcrumb", {
          staticClass: "mb-6",
          attrs: {
            title: "Apólice",
            actualPage: "Detalhes da apólice",
            previousPage: "apolices",
            previousPageTitle: "Lista de apólices"
          }
        })
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "detalhe-apolice new-tabs" },
      [
        _vm.policy.PolicyUniqueId
          ? _c(
              "vs-tabs",
              { attrs: { color: "warning" } },
              [
                _c(
                  "vs-tab",
                  { attrs: { label: "Dados gerais" } },
                  [_c("detalhar-apolice", { attrs: { policy: _vm.policy } })],
                  1
                ),
                _c(
                  "vs-tab",
                  { attrs: { label: "Histórico" } },
                  [
                    _c("historico-apolice", {
                      attrs: { history: _vm.policy.PolicyHistory }
                    })
                  ],
                  1
                ),
                _vm.policy.HasEndorsement
                  ? _c(
                      "vs-tab",
                      { attrs: { label: "Endosso(s)" } },
                      [
                        _c("endosso-list", {
                          attrs: { policyUniqueId: _vm.policy.PolicyUniqueId }
                        })
                      ],
                      1
                    )
                  : _vm._e()
              ],
              1
            )
          : _vm._e()
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }